@import url(https://fonts.googleapis.com/css?family=Lato:400,900);
html{
  font-family: 'Lato', sans-serif;
  background-color:#1A191A;
}
.container{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}
.container .logo img{
  padding:3em;
  width:100px;
  height:auto;
}

.container .grid-stills{
  display: -webkit-flex;
  display: flex;
  width: 100%;
}
.container .grid-stills .still{
  -webkit-flex: 1 1;
          flex: 1 1;
  padding:1em;
}
.container .grid-stills .still img{
  width:100%;
  height:auto;

}
.know-more{
  display:-webkit-flex;
  display:flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-flex: 1 1 100%;
          flex: 1 1 100%;padding:1em;
  color:#fff;
}
.know-more a{
  color:#fff;
  border-bottom:1px solid #fff;
  padding-bottom:0.3em;
  text-decoration:none;
}
.know-more a:hover{
  border-bottom:2px solid #fff;
  text-decoration:none;
  width:100px;
}
.wrapper-counter{
  display:-webkit-flex;
  display:flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}
.wrapper-counter p{
  font-size:12px;
}
.counter{
  color:#fff;
  width:80px;
  height:80px;
  display:-webkit-flex;
  display:flex;
  -webkit-justify-content: center;
          justify-content: center;
  font-size:50px;
  border: 1px solid #fff;
  -webkit-align-items: center;
          align-items: center;
}
p{
  color:#fff;
  letter-spacing: 3pt;
}
footer{
  display: -webkit-flex;
  display: flex;
  background-color:#000;
  border-top:1px solid #fff;
  height: 80px;
  width: 100%;
  position: fixed;
  bottom:0;
  color:#fff;
  -webkit-align-items: center;
          align-items: center;
}
footer .copyright{
  -webkit-flex:1 1 50%;
          flex:1 1 50%;
  text-align: left;
  padding:1em;
}
footer .social{
  -webkit-flex:1 1 50%;
          flex:1 1 50%;
  text-align: right;
  padding:1em;
}
footer .social img{
  margin-left:1em;
  width:20px;
  height:auto;
}
@media (max-width: 400px) {
  .container .grid-stills{
    display: block;
  }
}
.mega-bottom{
  margin-bottom:6em;
}

